import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'

import Sidebar from './sidebar'
import CookieConsent from 'react-cookie-consent'

const Container = styled.div`
  min-height: 100vh;
  display: flex;
`

const Content = styled.div`
  /* Device = Tablets, iPads (portrait) */
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-left: 20%;
  }

  /* Device = Tablets, iPads (landscape) */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    margin-left: 20%;
  }

  /* Device = Low resolution Tablets, Mobiles (landscape) */
  @media (min-width: 320px) and (max-width: 767px) {
    margin-left: 10%;
  }

  @media (min-width: 1025px) {
    margin-left: 30%;
  }

  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  overflow: scroll;
  padding: 20px;
  color: #7e7e7e;
`

const StyledLink = styled(Link)`
  color: #CC5803;
`

const Query = graphql`
  query {
    site {
      siteMetadata {
        title
        authorName
      }
    }
  }
`

export default ({ children }) => (
  <StaticQuery
    query={Query}
    render={data => {
      const { title, siteDescription, authorName } = data.site.siteMetadata
      return (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <meta name="description" content={siteDescription} />
            <title>{title}</title>
          </Helmet>
          <Container>
            <Sidebar title={title} authorName={authorName} />
            <Content>{children}</Content>
            <CookieConsent
              location="bottom"
              buttonText="Zgadzam się"
              declineButtonText="Nie zgadzam się"
              cookieName="gatsby-gdpr-google-analytics"
              style={{ background: "rgba(12, 12, 12, 0.7)" }}
              buttonStyle={{ background: "#ffd972" }}
              >
                Korzystam z plików cookies na stronie w celu zapewnienia jej prawidłowego działania oraz po to, by analizować ruch i prowadzić działania marketingowe. 
                Szczegóły znajdziesz w <StyledLink to='/policy'>polityce prywatności</StyledLink>.
            </CookieConsent>
          </Container>
        </>
      )
    }}
  />
)

import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

export default () => (
    <Footer>
        © Copyright {(new Date()).getFullYear()} piotrbaran.it <span>|</span> <StyledLink to='policy'>Polityka prywatności</StyledLink>
    </Footer>
)

const Footer = styled.div`
    @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 0.6rem;
        margin: 1rem;
    }

    @media (min-width: 320px) and (max-width: 767px) {
        transform: rotate(-90deg);
        transform-origin: center;
        min-width: 10rem;
        margin-bottom: 5rem;
        font-size: 0.6rem;
    }

    align-self: center;
    flex-shrink: 0; 
    font-size: 0.8rem;
    margin: 1rem;
`

const StyledLink = styled(Link)`
    color: #cc5803;
`

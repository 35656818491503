import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import {
  FaHome,
  FaUserSecret,
  FaLinkedin,
  FaGithub,
  FaMailBulk,
  FaBook
} from 'react-icons/fa'

import logo from "../images/logo.png"
import Footer from './footer'

const Sidebar = styled.div`
  /* Device = Tablets, iPads (portrait) */
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 20%;
  }

  /* Device = Tablets, iPads (landscape) */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    width: 20%;
  }

  /* Device = Low resolution Tablets, Mobiles (landscape) */
  @media (min-width: 320px) and (max-width: 767px) {
    width: 10%;
    justify-content: flex-start;
    align-items: center;

    &::before {
      content: '';
      margin: 10px;
    }
  }

  position: fixed;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 30%;
  background-color: #ffd972;
  color: #222222;
  justify-content: center;
  align-items: center;
  max-width: 25rem;
`

const Logo = styled.img`
  /* Device = Low resolution Tablets, Mobiles (landscape) */
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }

  overflow: hidden;
  max-width: 100%;
`

const StyledLinkTitle = styled(Link)`
  text-decoration: none;
  color: inherit;

  @media (min-width: 320px) and (max-width: 767px) {
    display: flex;
    flex: 1;
  }

`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`

const StyledHref = styled.a`
  text-decoration: none;
  color: inherit;
`

const MenuWrapper = styled.div`
  @media (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
    flex: 1;
    div {
      margin: 0;
    }
    span {
      display: none;
    }
  }

  /* Device = Tablets, iPads (portrait) */
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 0.6rem;
  }

  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  div {
    margin: 10px;
  }
`

const SiteTitle = styled.h1`
  font-family: 'Montserrat';
  display: flex;

  @media (min-width: 768px) {
    display: none;
  }

  /* Device = Tablets, iPads (portrait) */
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 16px;
  }

  /* Device = Tablets, iPads (landscape) */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    font-size: 16px;
  }

  /* Device = Low resolution Tablets, Mobiles (landscape) */
  @media (min-width: 320px) and (max-width: 767px) {
    transform: rotate(-90deg);
    flex: 1;
    font-size: 1rem;
    margin: 2rem 0;
    width: 15rem;
    height: max-content;
  }
`

export default ({ title, authorName }) => (
  <Sidebar>
    <StyledLinkTitle to="/">
    <Logo
      src={logo}
      alt={authorName}
    />
    <SiteTitle>{title}</SiteTitle>
    </StyledLinkTitle>
    <MenuWrapper>
      <div>
        <p>
          <StyledLink to="/">
            <FaHome /> <span>Home</span>
          </StyledLink>
        </p>
        <p>
          <StyledLink to="/courses">
            <FaBook /> <span>Szkolenia</span>
          </StyledLink>
        </p>
        <p>
          <StyledLink to="/contact">
            <FaMailBulk /> <span>Kontakt</span>
          </StyledLink>
        </p>
      </div>
      <div>
        <p>
          <StyledHref
            href="https://www.linkedin.com/in/piotr-baran/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FaLinkedin /> <span>Linkedin</span>
          </StyledHref>
        </p>
      </div>
    </MenuWrapper>
    <Footer></Footer>
  </Sidebar>
)
